const UserReducer = (state, action) => {
    switch (action.type) {
      case "LOGIN_START":
        return {
          ...state,
          isUserError: true,
          isLoading: true,
          isError: false,
        };
      case "LOGIN_RESET":
        return {
          ...state,
          isLoading: false,
          isError: false,
        };
      case "LOGIN_SUCCESS":
        return {
          ...state,
          isLoading: false,
          isError: false,
          auth: action.payload,
        };
        case "API_SUCCESS":
        return {
          ...state,
          isLoading: false,
          isError: false,
        };
      case "LOGIN_ERROR":
        return {
          ...state,
          isLoading: false,
          isError: true,
        };
      case "GET_USER_PROFILE_LIST":
        return {
          ...state,
          isLoading: false,
          isUserError: false,
          profileList: action.payload?.content,
          totalRow:action.payload?.totalElements
        };
        case "GET_LOGGED_USER_DETAILS_START":
          return {
            ...state,
            isLoading: false,
            isUserDetailLoading: true,
          };
      case "GET_USER_PROFILE_DETAILS_SUCCESS":
        return {
          ...state,
          isUserDetailLoading: false,
          isUserError: false,
          profileDetails: action.payload,
        };
      case "GET_LOGGED_USER_DETAILS_FAILED":
        return {
          ...state,
          isLoading: false,
          isUserError: true,
        };
      case "GET_APPLICATION_DETAILS":
        return {
          ...state,
          applicationIds: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default UserReducer;
  