import { createContext, useReducer } from "react";
import { toast } from "react-toastify";
import { axios,axiosPrivate } from "../../api/axios";
import TokenService from "../../api/token.service";
import AuthReducer from "./AuthReducer";
import {useNavigate } from "react-router-dom";

const AuthContext = createContext({});

const intialAuthState = {
  isLoading: false,
  isError: false,
  userDetails: JSON.parse(localStorage.getItem("userDetail")) || {},
 
};


export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, intialAuthState);
  const navigate = useNavigate();

  const UserRegisterAPI = async (params) => {
    dispatch({ type: "LOGIN_START" });
    try {
      const response = await axiosPrivate.post("/auth/login", JSON.stringify(params), {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      const { data } = response;
      if (data.success && data.message === "Login Successfull") {
        const accessToken = data.data.token;
        dispatch({ type: "LOGIN_SUCCESS", payload: accessToken });
      }  else {
        toast.error(data.message, { position: "bottom-right", autoClose: 2000, theme: "colored" });
        dispatch({ type: "LOGIN_ERROR" });
      }
      return data;
    } catch (error) {
      dispatch({ type: "LOGIN_ERROR" });
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      toast.error(message, { position: "bottom-right", autoClose: 2000, theme: "colored" });
    }
  };
  const UserLoginAPI = async (params) => {
    dispatch({ type: "LOGIN_START" });
    try {
      const response = await axiosPrivate.post("auth/authenticate", JSON.stringify(params), {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      const { data } = response;
      TokenService.setUser(data.access_token);
      getUserDetails(data.userId)
      dispatch({ type: "LOGIN_SUCCESS", payload: data.access_token });
      // if (data.success && data.message === "Login Successfull") {
      //   const accessToken = data.data.token;
      //   dispatch({ type: "LOGIN_SUCCESS", payload: accessToken });
      // }  else {
      //   toast.error(data.message, { position: "bottom-right", autoClose: 2000, theme: "colored" });
      //   dispatch({ type: "LOGIN_ERROR" });
      // }
      return data;
    } catch (error) {
      dispatch({ type: "LOGIN_ERROR" });
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      toast.error(message, { position: "bottom-right", autoClose: 2000, theme: "colored" });
    }
  };
  const getUserDetails = async (id) => {
    dispatch({ type: "GET_LOGGED_USER_DETAILS" });
    try {
      const response = await axiosPrivate.get(`/profiles/details/${id}`);
      const { data } = response;
      if(data.response=="OK")
        {
          localStorage.setItem("userDetail", JSON.stringify(data.responseObject));
          dispatch({ type: "GET_LOGGED_USER_DETAILS_SUCCESS", payload: data.responseObject });
          toast.success(data.message, { position: "bottom-right", autoClose: 1000, theme: "colored" });
          navigate("/admin");
        }
     
    } catch (error) {
      console.log(error);
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      toast.error(message, { position: "bottom-right", autoClose: 2000, theme: "colored" });
      dispatch({ type: "GET_LOGGED_USER_DETAILS_FAILED" });
    }
  };
  const getLogOut = async (params) => {
    dispatch({ type: "LOGIN_START" });
    try {
      const response = await axiosPrivate.post("/user/logout");
      const { data } = response;
      dispatch({ type: "LOGIN_SUCCESS", payload: ""});
      TokenService.removeUser();
      navigate("/login");
      return data;
    } catch (error) {
      dispatch({ type: "LOGIN_ERROR" });
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      toast.error(message, { position: "bottom-right", autoClose: 2000, theme: "colored" });
    }
  };
  

  return (
    <AuthContext.Provider
      value={{
        UserRegisterAPI,
        UserLoginAPI,
        getLogOut,
        isLoading: state.isLoading,
        auth: state.auth,
        isError: state.isError,
        dispatch,
        userDetails:state.userDetails
       
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;