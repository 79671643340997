import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Alert } from "react-bootstrap";

import {
  Button,
  Typography,
  TextField,
  Box,
  MenuItem,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  InputLabel ,
  InputAdornment,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/en-gb";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { format } from "date-fns";
import { useParams, useLocation } from "react-router-dom";


const AddressDetailForm = ({ personalData,stateList,stateID,
 }) => {
  const location = useLocation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      country: personalData?.country ? personalData?.country : "",
      state: stateID,
      city: personalData?.city ? personalData?.city : "",
      zipCode:personalData?.zip ? personalData?.zip : "",
      resAddress:personalData?.address ? personalData?.address : "",
    },
  });
  useEffect(() => {
    return () => {
      reset();
    };
  }, [location, reset]);
  const onSubmit = (data) => {
    console.log(data);
    let inputData={
      "country": data.country,
      "state": data.state,
      "city": data.city,
      "zip": data.zipCode,
      "address": data.resAddress,
     
    }
    //submitFrom("Address",personalData?.userId,inputData)
  };
  return (
    <>
   
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card-body">
          <div className="row gy-3">
            <div className="col-md-12">
              <Controller
                name="country"
                control={control}
                rules={{ required: "Country is required" }}
                render={({ field }) => (
                  <FormControl fullWidth margin="normal">
                    <TextField
                      label="Country"
                      {...field}
                      error={!!errors.country}
                      helperText={errors.country ? errors.country.message : ""}
                    >
                    </TextField>
                  </FormControl>
                )}
              />
            </div>
            
            <div className="col-md-6">
              <Controller
                name="state"
                control={control}
                rules={{ required: "State is required" }}
                render={({ field }) => (
                  <FormControl fullWidth margin="normal">
                    <TextField
                      select
                      size="small"
                      label="Select your state"
                      {...field}
                      error={!!errors.state}
                      helperText={errors.state ? errors.state.message : ""}
                    >
                      {stateList?.map((val, index) => (
                        <MenuItem value={val.id}>{val.name}</MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                )}
              />
            </div>
            <div className="col-md-12">
              <Controller
                name="city"
                control={control}
                rules={{ required: "City is required" }}
                render={({ field }) => (
                  <FormControl fullWidth margin="normal">
                    <TextField
                      label="City"
                      {...field}
                      error={!!errors.city}
                      helperText={errors.city ? errors.city.message : ""}
                    >
                    </TextField>
                  </FormControl>
                )}
              />
            </div>
            <div className="col-md-12">
              <Controller
                name="zipCode"
                control={control}
                rules={{ required: "Zip code is required" }}
                render={({ field }) => (
                  <FormControl fullWidth margin="normal">
                    <TextField
                      label="Zip Code"
                      {...field}
                      error={!!errors.zipCode}
                      helperText={errors.zipCode ? errors.zipCode.message : ""}
                    >
                    </TextField>
                  </FormControl>
                )}
              />
            </div>
            <div className="col-md-12">
              <Controller
                name="resAddress"
                control={control}
                rules={{ required: "Residential Address is required" }}
                render={({ field }) => (
                  <FormControl fullWidth margin="normal">
                    <TextField
                      label="Residential Address"
                      {...field}
                      error={!!errors.resAddress}
                      helperText={errors.resAddress ? errors.resAddress.message : ""}
                    >
                    </TextField>
                  </FormControl>
                )}
              />
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="text-end d-flex justify-content-end">
            <button className="btn btn-primary-light me-2">
              <i className="ri ri-refresh-line me-1" /> Update{" "}
            </button>
            <button className="btn btn-secondary-light">
              <i className="ri ri-close-circle-line me-1" />
              Cancel
            </button>
          </div>
        </div>
      </form>
      
   
    </>
  );
};

export default AddressDetailForm;
