import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import {

  Typography,
  TextField,
  Box,
  MenuItem,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  InputAdornment,
  Autocomplete
} from "@mui/material";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Basic styles
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Link } from "react-router-dom";

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faBars} from "@fortawesome/free-solid-svg-icons";
import LOGO from "../../assets/img/bm-img/bm-logo.jpg"
import Row from 'react-bootstrap/Row';
import Select from 'react-select';
import LandingHeader from '../../components/Landing/Header/landingheader';
import LandinFooter from '../../components/Landing/Footer/landingfooter';
const drawerWidth = 240;
const navItems = ['Login', 'Contact'];


const DeleteProfile = (props) => {
  
  return (
    <>
     
     <Container fluid style={{marginBottom:"50px;"}}>
      <Row>
      <LandingHeader></LandingHeader>
     
      <div className="row" style={{"margin-top":"125px","minHeight":"77vh"}}>
        <div className="col-md-12">
            <h6 className="fw-bold pb-3"><span class="">Hide or Delete Your Profile</span></h6>
            <div className="mb-4">
                <p class="fw-semibold mb-2 fs-14">If you wish to hide or delete your profile on <strong>Banagars Matrimony</strong>, please follow the guidelines below:</p>
              
            </div>
            <div className="mb-4">
                <ul>
                    <li >Hiding Your Profile</li>
                    <ul className="mb-0 text-muted">
                        <li> When you choose to hide your profile, it will not be visible to other members on <strong>Banagars Matrimony</strong>. 
                        Your activities, such as sending interest, responding to interest, and chats, will be restricted until you unhide your profile.</li>  
                        <li>We suggest using this feature if you wish to put your partner search on hold temporarily or if you have found someone but have yet to finalize. 
                        You can hide your profile for up to <strong>30 days</strong>.</li>   
                        <li> However, if you are leaving due to any unpleasant experience, we would be happy to address your concerns. 
                        Please note that once you deactivate your profile, all details will be hidden from other users .</li>   
                    </ul>
                </ul>
                
            </div>
            <h6 className="fw-bold pb-3 mt-4"><span class="">Steps to deactivate your Profile</span></h6>
            <div className="mb-4">
                <p class="fw-semibold mb-2 fs-14">If you are using the <strong>Banagars Matrimony</strong> app, follow these steps:</p>
              
            </div>
            <div className="mb-4">
               
                <ul className="mb-0 text-muted">
                    <li>Go to the <strong>Settings</strong> screen.</li>
                    <li>Click on the <strong>Deactivate account</strong> below Admin Status.</li>
                    <li>Wait for the update, The status will be changed immediately.</li>
                  
                </ul>
                
            </div>
            <p class="note">
            Note: All mailers will stop immediately of deactivating your profile.
            Data will be erased after 30 days and cannot be recoverd.
            If you need any further assistance please reach out to our email: banagarsmatrimony01@gmail.com and Ph: +918904875526.
        </p>
            

        </div>
            
      </div>  
    
         
      </Row>
      </Container>
      
      <LandinFooter></LandinFooter>
    </>
  );
}



export default DeleteProfile;