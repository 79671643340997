import React, { useContext, useState } from "react";

import { useForm, Controller } from 'react-hook-form';
import {
  Stepper, Step, StepLabel, Button, Typography, TextField, Box, MenuItem, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio,InputAdornment
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import "./profileSetting.css";

  const steps = ['Personal Information', 'Contact Details', 'Review'];
const ProfileSetting = () => {

    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const [activeStep, setActiveStep] = useState(0);
  const { control, handleSubmit, trigger, formState: { errors } } = useForm();
  const [formData, setFormData] = useState({
    name: '',
    age: '',
    email: '',
    phone: '',
  });

  const handleNext = async () => {
    const isValid = await trigger(getStepFields(activeStep));
    console.log(isValid);
    if (isValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onSubmit = (data) => {
    setFormData(data);
    handleNext();
  };

  const getStepFields = (step) => {
    switch (step) {
      case 0:
        return ['brithTime',"edu","prof","phyChal","monthEarn","height","weight","mstatus","aboutMe"];
      case 1:
        return ['email', 'phone'];
      default:
        return [];
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div className="row">
             <div className="col-md-12">
                <Controller
                  name="brithTime"
                  control={control}
                  defaultValue={formData.brithTime}
                  rules={{ required: 'Birth Time is required' }}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileTimePicker  
                    
                      {...field}
                      label="Preferred Time"
                      renderInput={(params) => (
                        <TextField
                        {...params}
                        sx={{ 
                          width:700 , 
                          '& .MuiInputBase-root': {
                            backgroundColor: '#f0f0f0',
                          },
                          '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#3f51b5',
                          },
                          '& .Mui-error .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#f44336',
                          },
                        }}
                          label="Birth Time"
                          margin="normal"
                          fullWidth
                          size="small"
                          error={!!errors.brithTime}
                          helperText={errors.brithTime ? errors.brithTime.message : ''}
                        />
                      )}
                    />
                  </LocalizationProvider>
                    
                  )}
                />
             </div>
              <div className="col-md-6">
                <Controller
                  name="edu"
                  control={control}
                  defaultValue={formData.edu}
                  rules={{ required: 'Education is required' }}
                  render={({ field }) => (
                    <FormControl fullWidth margin="normal">
                      <TextField
                        select
                        size="small"
                        label="Select your education"
                        {...field}
                        error={!!errors.edu}
                        helperText={errors.edu ? errors.edu.message : ''}
                      >
                        <MenuItem value="ba">BA</MenuItem>
                        <MenuItem value="be">BE</MenuItem>
                        <MenuItem value="bhms">BHMS</MenuItem>
                      </TextField>
                    </FormControl>
                  )}
                />
              </div>
              <div className="col-md-6">
                  <Controller
                  name="prof"
                  control={control}
                  defaultValue={formData.edu}
                  rules={{ required: 'Profession is required' }}
                  render={({ field }) => (
                    <FormControl fullWidth margin="normal">
                      <TextField
                        select
                        size="small"
                        label="Select your profession"
                        {...field}
                        error={!!errors.prof}
                        helperText={errors.prof ? errors.prof.message : ''}
                      >
                        <MenuItem value="AC">Accountant</MenuItem>
                        <MenuItem value="ENG">Enginner</MenuItem>
                        <MenuItem value="doctor">Doctor</MenuItem>
                      </TextField>
                    </FormControl>
                  )}
              />
              </div>
            
              <div className="col-md-12">
              <Controller
              name="phyChal"
               size="small"
              control={control}
              defaultValue={formData.phyChal}
              rules={{ required: 'Physically is required' }}
              render={({ field }) => (
                <FormControl fullWidth component="fieldset" margin="normal" error={!!errors.phyChal}>
                    <div className="row">
                        <div className="col-md-4">
                        <FormLabel style={{"marginTop": "8px"}} component="legend"className="d-inline" >Physically Challenged</FormLabel>
                        </div>
                        <div className="col-md-8">
                          <RadioGroup style={{ width: 'auto' }} row  {...field}>
                            <FormControlLabel value="no" control={<Radio />} label="No" />
                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                            
                          </RadioGroup>
                        </div>
                    </div>
                 
                  
                  {errors.phyChal && <Typography color="error">{errors.phyChal.message}</Typography>}
                </FormControl>
              )}
            />
              </div>
          
            <div className="col-md-12">
            <Controller
              name="monthEarn"
            
              control={control}
              defaultValue={formData.monthEarn}
              rules={{ required: 'Monthly Earnings is required',validate: {
                positive: value => parseFloat(value) > 0 || 'Enter vaild amount',
                decimalPlaces: value => /^\d+(\.\d{1,2})?$/.test(value) || 'Amount can have up to 2 decimal places',
              }, }}
              render={({ field }) => (
                <TextField
                  {...field}
                     size="small"
                  label="Monthly Earnings"
                  margin="normal"
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="end">&#8377;</InputAdornment>,
                  }}
                  
                  error={!!errors.monthEarn}
                  helperText={errors.monthEarn ? errors.monthEarn.message : ''}
                />
              )}
            />
            </div>
            
             <div className="col-md-6">
             <Controller
              name="height"
              control={control}
              
              defaultValue={formData.height}
              rules={{ required: 'Height is required' }}
              render={({ field }) => (
                <FormControl fullWidth margin="normal">
                  <TextField
                    select
                     size="small"
                    label="Select your height"
                    {...field}
                    error={!!errors.height}
                    helperText={errors.height ? errors.edu.message : ''}
                  >
                    <MenuItem value="5.0">5.0ft</MenuItem>
                    <MenuItem value="5.5">5.5ft</MenuItem>
                    <MenuItem value="6.0">6.0ft</MenuItem>
                  </TextField>
                </FormControl>
              )}
            />
             </div>
            
             <div className="col-md-6">
              <Controller
                name="weight"
              
                control={control}
                defaultValue={formData.weight}
                rules={{ required: 'Weight is required' }}
                render={({ field }) => (
                  <FormControl fullWidth margin="normal">
                    <TextField
                      select
                        size="small"
                      label="Select your weight"
                      {...field}
                      error={!!errors.weight}
                      helperText={errors.weight ? errors.weight.message : ''}
                    >
                      <MenuItem value="40">40kg</MenuItem>
                      <MenuItem value="45">45kg</MenuItem>
                      <MenuItem value="50">50kg</MenuItem>
                    </TextField>
                    
                  </FormControl>
                )}
              />
             </div>
             <div className="col-md-12">
             <Controller
              name="mstatus"
              
              control={control}
              defaultValue={formData.mstatus}
              rules={{ required: 'Marital is required' }}
              render={({ field }) => (
                <FormControl fullWidth margin="normal">
                  <TextField
                    select
                     size="small"
                    label="Select your Marital status"
                    {...field}
                    error={!!errors.mstatus}
                    helperText={errors.mstatus ? errors.mstatus.message : ''}
                  >
                    <MenuItem value="40">40kg</MenuItem>
                    <MenuItem value="45">45kg</MenuItem>
                    <MenuItem value="50">50kg</MenuItem>
                  </TextField>
                </FormControl>
              )}
            />
              </div>
            
            <div className="col-md-12">
            <Controller
              name="aboutMe"
              control={control}
              defaultValue={formData.aboutMe}
              render={({ field }) => (
                <FormControl fullWidth margin="normal">
                  <TextField
                    label="About me"
                    {...field}
                    multiline
                    maxRows={4}
                    error={!!errors.aboutMe}
                    helperText={errors.aboutMe ? errors.aboutMe.message : ''}
                  >
                   
                  </TextField>
                </FormControl>
              )}
            />
            </div>
             
          </div>
        );
      case 1:
        return (
          <Box>
            <Controller
              name="email"
              control={control}
              defaultValue={formData.email}
              rules={{
                required: 'Email is required',
                pattern: {
                  value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                  message: 'Invalid email address',
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Email"
                  margin="normal"
                  fullWidth
                  error={!!errors.email}
                  helperText={errors.email ? errors.email.message : ''}
                />
              )}
            />
            <Controller
              name="phone"
              control={control}
              defaultValue={formData.phone}
              rules={{
                required: 'Phone number is required',
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Invalid phone number',
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Phone"
                  margin="normal"
                  fullWidth
                  error={!!errors.phone}
                  helperText={errors.phone ? errors.phone.message : ''}
                />
              )}
            />
          </Box>
        );
      case 2:
        return (
          <Box>
            <Typography variant="h6">Review your details</Typography>
            <Typography>Name: {formData.name}</Typography>
            <Typography>Email: {formData.email}</Typography>
            <Typography>Phone: {formData.phone}</Typography>
          </Box>
        );
      default:
        return 'Unknown step';
    }
  };
  return(
    <>
    
  <div className="container-lg">
    <div className="row justify-content-center align-items-center authentication authentication-basic h-100">
      <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
        
        <div className="card custom-card">
          <div className="card-body">
            <div className="authentication-cover d-block">
              <div className="aunthentication-cover-content">
                <p className="h5 fw-bold mb-2 text-center">Profile Setting</p>
                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                        ))}
                    </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography variant="h6">All steps completed</Typography>
            <Button onClick={() => setActiveStep(0)}>Reset</Button>
          </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            {getStepContent(activeStep)}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              {activeStep === steps.length - 1 ? (
                <Button variant="contained" color="primary" type="submit">
                  Finish
                </Button>
              ) : (
                <Button variant="contained" color="primary" onClick={handleNext}>
                  Next
                </Button>
              )}
            </Box>
          </form>
        )}
      </div>
    </Box>  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div></>);
};

export default ProfileSetting;
