import React, { useContext, useState } from "react";
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import SideMenu from '../../components/SideMenu/SideManu'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer} from 'react-toastify';
const Header = () => {
    
    return(
      <>
      <SideMenu></SideMenu>
      
        <Outlet />
        <ToastContainer />
      </>
  
);
}
export default Header;
