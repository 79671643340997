import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import {

  Typography,
  TextField,
  Box,
  MenuItem,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  InputAdornment,
  Autocomplete
} from "@mui/material";

import BAN from "../../assets/img/BAN-2.jpg"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Basic styles
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Link } from "react-router-dom";

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faBars} from "@fortawesome/free-solid-svg-icons";
import LOGO from "../../assets/img/bm-img/bm-logo.jpg"
import Row from 'react-bootstrap/Row';
import Select from 'react-select';
import LandingHeader from '../../components/Landing/Header/landingheader';
import LandinFooter from '../../components/Landing/Footer/landingfooter';
const drawerWidth = 240;
const navItems = ['Login', 'Contact'];


const TermUse = (props) => {
  
  return (
    <>
     
     <Container fluid style={{marginBottom:"50px;"}}>
      <Row>
      <LandingHeader></LandingHeader>
     
      <div className="row" style={{"margin-top":"125px"}}>
        <div className="col-md-12">
            <h6 className="fw-bold pb-3"><span class="">Privacy Policy for Banagars Matrimony</span></h6>
            <div className="mb-4">
                <p class="fw-semibold mb-2 fs-14">Banagars Matrimony is committed to protecting your privacy and ensuring the confidentiality of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our services. By accessing or using Banagars Matrimony, you agree to the practices described in this policy.</p>
              
            </div>
            <div className="mb-4">
                <p className="fw-semibold mb-2 fs-14">1. Information We Collect</p>
                <p className="mb-0 text-muted">
                To provide you with our matrimonial services, Banagars Matrimony may collect the following types of information:
                </p>
                <ul>
                    <li >A. Personal Information:</li>
                    <ul className="mb-0 text-muted">
                        <li>Name</li>  
                        <li>  Gender</li>  
                        <li>Date of Birth (for eligibility purposes)</li>    
                        <li>Contact details (phone number, email address, postal address)</li>   
                        <li>Marital status, religion, caste, and other preferences relevant to matrimonial matchmaking</li>   
                        <li>Photographs that you upload to your profile</li> 
                        <li>Educational and professional information
                        Family details</li> 
                    </ul>
                </ul>
                <ul>
                    <li>B. Usage Data:</li>
                    <ul className="mb-0 text-muted">
                        <li>IP addresses, browser types, operating systems, and other usage information when you interact with our website or mobile app.
                        Information on how you interact with other users or use features of the platform.</li>  
                       
                    </ul>
                </ul>
                <ul>
                    <li>C. Location Information:</li>
                    <ul className="mb-0 text-muted">
                        <li>We may collect your location information (with your consent) to provide location-based services or match suggestions.</li>  
                       
                    </ul>
                </ul>
            </div>
            <div className="mb-4">
                <p className="fw-semibold mb-2 fs-14">2. How We Use Your Information</p>
                <p className="mb-0 text-muted">
                We use the information we collect to:
                </p>
                <ul className="mb-0 text-muted">
                    <li>Create and manage your profile on Banagars Matrimony.</li>
                   <li>Match you with potential matrimonial partners based on your preferences.</li>
                   <li>Communicate with you about your account, services, and important updates.</li>
                   <li>Personalize your experience on the platform and show you relevant profiles.</li>
                   <li>Prevent fraud or abuse and ensure the safety of our users.</li>
                   <li>Comply with legal obligations where required.</li>
                  
                </ul>
                
            </div>
            <div className="mb-4">
                <p className="fw-semibold mb-2 fs-14">3. Information Sharing and Disclosure</p>
                <p className="mb-0 ">
                Banagars Matrimony does not sell or rent your personal information to third parties. However, we may share your information in the following circumstances:
                </p>
                <ul className="mb-0 text-muted">
                    <li>With Other Users: Your profile information (except your personal contact number) will be (Your parents or guardian) visible to other users of Banagars Matrimony for matchmaking purposes.</li>
                   <li>Service Providers: We may share your information with third-party service providers who assist us in operating the platform or providing services, such as payment processing or customer support.</li>
                   <li>Legal Requirements: We may disclose your information to law enforcement or government authorities if required by law or to protect our legal rights.</li>
                   <li>Business Transfers: If Banagars Matrimony undergoes a merger, acquisition, or sale of assets, your information may be transferred to the new entity.</li>
                  
                  
                </ul>
                
            </div>
            <div className="mb-4">
                <p className="fw-semibold mb-2 fs-14">4. Data Security</p>
                <p className="mb-0 ">
                Banagars Matrimony takes reasonable measures to protect your information from unauthorized access, alteration, disclosure, or destruction. These measures include:
                </p>
                <ul className="mb-0 text-muted">
                    <li>Encryption of sensitive information.</li>
                   <li>Regular security audits and updates.</li>
                   <li>Secure servers and firewalls to protect your data.</li>
                   <li>However, please note that no method of online transmission or storage is 100% secure, and we cannot guarantee absolute security.</li>
                  
                  
                </ul>
                
            </div>
            <div className="mb-4">
                <p className="fw-semibold mb-2 fs-14">5. Your Rights and Choices</p>
                <p className="mb-0 ">
                You have the following rights regarding your personal information:
                </p>
                <ul className="mb-0 text-muted">
                    <li>Access and Update: You can view and update your profile information at any time through your account settings.</li>
                   <li>Deactivate Your Account: You can deactivate your Banagars Matrimony account at any time, and all your personal information will not be visible to other users, except for data we are required to retain by law.</li>
                   <li>Opt-Out of Communications: You can opt-out of receiving promotional communications from us by following the instructions in the emails or updating your preferences in your account.</li>
                  
                  
                  
                </ul>
                
            </div>
            <div className="mb-4">
                <p className="fw-semibold mb-2 fs-14">6. Cookies and Tracking Technologies</p>
                <p className="mb-0 ">
                Banagars Matrimony uses cookies and similar tracking technologies to:
                </p>
                <ul className="mb-0 text-muted">
                    <li>Provide and improve your experience on the platform.</li>
                   <li>Remember your preferences and settings.</li>
                   <li>Track usage statistics and user behavior for better service delivery.</li>
                   <li>You can adjust your browser settings to refuse cookies or alert you when cookies are being used, but this may impact certain functionalities of the platform.</li>
                  
                  
                  
                </ul>
                
            </div>
            <div className="mb-4">
                <p className="fw-semibold mb-2 fs-14">7. Children's Privacy</p>
                <p className="mb-0 text-muted">
                Banagars Matrimony is intended for users above the legal age of marriage in India (18 for females, 21 for males). We do not knowingly collect personal information from anyone under this age. If we become aware that we have collected information from a child under the legal age, we will take steps to delete such information.
                </p>
              
            </div>
          
            <div className="mb-4">
                <p className="fw-semibold mb-2 fs-14">8. Changes to This Privacy Policy</p>
                <p className="mb-0 text-muted">
                Banagars Matrimony reserves the right to update or modify this Privacy Policy at any time. We will notify you of any significant changes by posting a notice on our website or sending you an email. Your continued use of the platform after any modifications constitutes acceptance of the updated policy.
                </p>
               
            </div>
            <div className="mb-4">
                <p className="fw-semibold mb-2 fs-14">9. Contact Us</p>
                <p className="mb-0 text-muted">
                If you have any questions or concerns about this Privacy Policy, please contact us at:
                </p>
                <ul className="text-muted">
                    <li>Email: banagarsmatrimony@gmail.com</li>
                   <li>Phone: +918904875526</li>
                   <li>Whats Up: +918904875526</li>
                   <li>This Privacy Policy was last updated on 10-12-2024.</li>
                
                  
                </ul>
                
            </div>
            <div className="mb-4">
                <p className="fw-semibold mb-2 fs-14">7. Termination</p>
                <p className="mb-0 text-muted">
                Banagars Matrimony reserves the right to terminate or suspend your account at any time, with or without cause, and with or without prior notice. Upon termination, your right to use the service will cease immediately.

                </p>
                
            </div>
            <div className="mb-4">
                <p className="fw-semibold mb-2 fs-14">8. Disclaimers and Limitation of Liability</p>
                <p className="mb-0 text-muted">
                Banagars Matrimony provides the service on an "as is" and "as available" basis without any warranties, either express or implied.
                </p>
                <p className="mb-0 text-muted">
                We do not guarantee the accuracy, completeness, or usefulness of any content, and you use Banagars Matrimony at your own risk.
                </p>
                <p className="mb-0 text-muted">Banagars Matrimony shall not be liable for any damages, including but not limited to, loss of data, emotional distress, or any other damages resulting from the use or inability to use the platform.</p>
            </div>
            <div className="mb-4">
                <p className="fw-semibold mb-2 fs-14">9. Governing Law</p>
                <p className="mb-0 text-muted">
                These Terms of Use shall be governed by and construed in accordance with the laws of laws of India. Any disputes arising under or in connection with these terms shall be resolved exclusively in the courts of Belagavi, karnataka.
                </p>
                
            </div>
            <div className="mb-4">
                <p className="fw-semibold mb-2 fs-14">10. Changes to Terms</p>
                <p className="mb-0 text-muted">
                Banagars Matrimony reserves the right to modify these Terms of Use at any time. Your continued use of the platform after such changes constitutes your acceptance of the revised terms.

                </p>
                
            </div>
            <div className="mb-4">
                <p className="fw-semibold mb-2 fs-14">
                11. Contact Information</p>
                <p className="mb-0 text-muted">
                If you have any questions or concerns regarding these Terms of Use, please contact us at:

                </p>
                <p className="mb-0 text-muted">Email: banagarsmatrimony@gmail.com</p>
                <p className="mb-0 text-muted">Phone: +918904875526</p>
                
            </div>
            <div className="mb-4">
                
                <p className="mb-0 text-muted">
                By using Banagars Matrimony, you agree to these terms and any future updates or amendments.

                </p>
                <p className="mb-0 text-muted">This version of the Terms of Use was last updated on 10-12-2024.</p>
                
                
            </div>
            

        </div>
            
      </div>  
    
         
      </Row>
      </Container>
      
      <LandinFooter></LandinFooter>
    </>
  );
}



export default TermUse;