import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Alert } from "react-bootstrap";
import AuthContext from "../../context/user-auth/AuthProvider";
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Login = () => {
    const { UserRegisterAPI ,UserLoginAPI,isLoading} = useContext(AuthContext);
    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
        watch,
      } = useForm({
        defaultValues: {
          
        },
      });
      const onSubmit = async (e) => {
        const response = await UserLoginAPI(e);
       
      };
      
    return(
  <div className="container-lg">
   
   
  <div className="row justify-content-center align-items-center authentication authentication-basic h-100">
      <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-6 col-sm-8 col-12">
        
          <div className="card custom-card">
              <div className="card-body">
                  <div className="authentication-cover">
                      <div className="aunthentication-cover-content">
                          <p className="h4 fw-bold mb-2 text-center">Sign in</p>
                          
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row gy-3">
                                <div className="col-xl-12">
                                    <label for="signup-Email" className="form-label text-default op=8">Email address</label>
                                    <input
                                    type="Email"
                                    className="form-control form-control-lg"
                                    id="signup-email"
                                    placeholder="Email"
                                    control={control} {...register("email", { required: "This field is required" ,pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: "Please enter valid email",
                                    }})} aria-invalid={errors.email ? true : false} 
                                    />
                                    <ErrorMessage
                                    errors={errors}
                                    name="email"
                                    render={({ message }) => (
                                        <Alert variant="danger" className="p-1 mb-0">
                                        {message}
                                        </Alert>
                                    )}
                                    />
                                </div>
                                <div className="col-xl-12">
                                    <label  className="form-label text-default d-block">password
                                       
                                    </label>
                                    <div className="input-group">
                                    <input
                                        type="password"
                                        className="form-control form-control-lg"
                                        id="signup-password"
                                        placeholder="password"
                                        control={control} {...register("password", { required: "This field is required" ,
                                        minLength: {
                                            value: 6,
                                            message: "Password must be at least 6 characters"
                                        },
                                        pattern: {
                                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&]).{6,}$/,
                                            message: "Password must contain atleast one uppercase, one lowercase, one number and special character.",
                                        }})} aria-invalid={errors.password ? true : false} 
                                        />
                                        <ErrorMessage
                                        errors={errors}
                                        name="password"
                                        render={({ message }) => (
                                            <Alert variant="danger" className="p-1 mb-0">
                                            {message}
                                            </Alert>
                                        )}
                                    />
                                    </div>
                                </div>
                                <div className="col-xl-12 d-grid mt-2">
                                <button type="submit" className="btn btn-lg btn-primary" disabled={isLoading}>
                                Sign Up {isLoading && <span class="spinner-border spinner-border-sm align-middle" role="status"
                            aria-hidden="true"></span>}
                                </button>
                                <div className="text-center d-none">
                                    <p className="fs-14 text-muted mt-3">Don't have an account yet ? <Link to="/register" className="text-primary fw-semibold"> Sign up here</Link></p>
                                </div>
                                </div>
                            </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <ToastContainer />
</div>
);
}
export default Login;
