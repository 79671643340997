import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import UserContext from "../../context/userProfile-list/UserProvider";
import { useNavigate,Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons'; 
import BlockLoader from "../../components/blockloader/BlockLoader"
const initialPagination = {
	pageIndex: 0,
	pageSize: 10,
};
const DashBoard = () => {
    const  navigate = useNavigate(); 
    const { UserProfileAPI,totalRow,isLoading} = useContext(UserContext);
   
   

const [pagination, setPagination] = useState(initialPagination);
useEffect(() => {
	UserProfileAPI(pagination);
   
  }, []);

    return(
        <>
        {isLoading && <BlockLoader position={"fixed"} />}
        {/* Start::app-content */}
        <div className="main-content app-content ">
          <div className="container-fluid">
            {/* Page Header */}
            <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
              <h1 className="page-title fw-medium fs-24 mb-0">Dashboard</h1>
              <div className="ms-md-1 ms-0">
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a href="javascript:void(0);">Home</a>
                    </li>
                    <li
                      className="breadcrumb-item active d-inline-flex"
                      aria-current="page"
                    >
                       Dashboard
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            {/* Page Header Close */}
          
            <div className="row">
        <div className="col-xl-2">
          <div className="card custom-card border-top-card border-top-primary rounded-0">
            <div className="card-body">
            <Link to="/admin/user" className="text-primary fw-semibold">
              <div className="text-center">
                <span className="avatar avatar-md bg-primary shadow-sm avatar-rounded mb-2">
                  <FontAwesomeIcon icon={faUsers} className="fs-16"/>
                </span>
                <p className="fs-14 fw-semibold mb-2">Total Profiles</p>
                <div className="d-flex align-items-center justify-content-center flex-wrap">
                  <h5 className="mb-0 fw-semibold">{totalRow}</h5>
                </div>
              </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* End::row-5 */}
           
          </div>
        </div>
        {/* End::app-content */}
      </>
      
  
);
}
export default DashBoard;
