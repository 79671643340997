import axios from "axios";
import TokenService from "./token.service";


//const BASE_URL =  "http://52.71.96.139:8080/bgsm/api/";

//const BASE_URL =  "https://banagars-matrimony.in/bgsm/api/";
const BASE_URL =  "http://3.111.225.227:8080/bgsm/api/";
//Create global cancellation token
export default axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});

axiosPrivate.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

