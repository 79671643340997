const CommonReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN_START":
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case "LOGIN_RESET":
      return {
        ...state,
        isLoading: false,
        isError: false,
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        auth: action.payload,
      };
    case "LOGIN_ERROR":
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case "GET_WEIGHT_LIST":
      return {
        ...state,
        isLoading: true,
        weightList: action.payload,
      };
    case "GET_HEIGHT_LIST":
      return {
        ...state,
        isLoading: true,
        heightList: action.payload,
      };
    case "GET_PROFESSIONS_LIST":
      return {
        ...state,
        isLoading: true,
        professionsList: action.payload,
      };
    case "GET_EDUCATION_LIST":
      return {
        ...state,
        isLoading: true,
        educationList: action.payload,
      };
    case "GET_STATE_LIST":
      return {
        ...state,
        isLoading: false,
        isUserError: false,
        stateList: action.payload,
      };
      case "GET_USER_PROFILE_DETAILS_SUCCESS":
        return {
          ...state,
          isLoading: false,
          isUserError: false,
          profileDetails: action.payload,
        };
    case "GET_LOGGED_USER_DETAILS_FAILED":
      return {
        ...state,
        isLoading: false,
        isUserError: true,
      };
    case "GET_APPLICATION_DETAILS":
      return {
        ...state,
        applicationIds: action.payload,
      };
    default:
      return state;
  }
};

export default CommonReducer;
