import React, { useContext, useState,useEffect } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Alert } from "react-bootstrap";
import AuthContext from "../../context/user-auth/AuthProvider";
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button,Modal, } from "react-bootstrap";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const LoginModal = (props) => {
    useEffect(() => {
       
          reset();
        
      }, []);
    const { UserRegisterAPI ,UserLoginAPI,isLoading} = useContext(AuthContext);
    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
        watch,
        reset,
      } = useForm({
        defaultValues: {
          
        },
      });
      const onSubmit = async (e) => {
        const response = await UserLoginAPI(e);
       
      };
      
    return(
        <Modal  {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header className="fs-5 fw-bold p-3">
            
                <div class="me-auto "> <p className="h4 fw-bold mb-2 text-center">Login in</p>{" "}</div>
                <div > <Button className="quickview__close text-end" onClick={()=>{reset();props.onHide();}}>
            <FontAwesomeIcon icon={faClose} />
          </Button></div>
       
         
        </Modal.Header>
        <Modal.Body className="p-3">
        <div className="container-lg">
   
   
   <div className="row authentication-basic ">
       <div className=" col-12">
         
         
                   <div className="authentication-cover">
                       <div className="aunthentication-cover-content">
                         
                           <form onSubmit={handleSubmit(onSubmit)}>
                             <div className="row gy-3">
                                 <div className="col-xl-12">
                                     <label for="signup-Email" className="form-label text-default op=8">Email address</label>
                                     <input
                                     type="Email"
                                     className="form-control form-control-lg"
                                     id="signup-email"
                                     placeholder="Email"
                                     control={control} {...register("email", { required: "This field is required" ,pattern: {
                                         value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                         message: "Please enter valid email",
                                     }})} aria-invalid={errors.email ? true : false} 
                                     />
                                     <ErrorMessage
                                     errors={errors}
                                     name="email"
                                     render={({ message }) => (
                                         <Alert variant="danger" className="p-1 mb-0">
                                         {message}
                                         </Alert>
                                     )}
                                     />
                                 </div>
                                 <div className="col-xl-12">
                                     <label  className="form-label text-default d-block">password
                                        
                                     </label>
                                     <div className="input-group">
                                     <input
                                         type="password"
                                         className="form-control form-control-lg"
                                         id="signup-password"
                                         placeholder="password"
                                         control={control} {...register("password", { required: "This field is required" ,
                                         minLength: {
                                             value: 6,
                                             message: "Password must be at least 6 characters"
                                         },
                                         pattern: {
                                             value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&]).{6,}$/,
                                             message: "Password must contain atleast one uppercase, one lowercase, one number and special character.",
                                         }})} aria-invalid={errors.password ? true : false} 
                                         />
                                         <ErrorMessage
                                         errors={errors}
                                         name="password"
                                         render={({ message }) => (
                                             <Alert variant="danger" className="p-1 mb-0">
                                             {message}
                                             </Alert>
                                         )}
                                     />
                                     </div>
                                 </div>
                                 <div className="col-xl-12 d-grid mt-2">
                                 <button type="submit" className="btn btn-lg btn-primary mt-3 mb-3" disabled={isLoading}>
                                 Sign Up {isLoading && <span class="spinner-border spinner-border-sm align-middle" role="status"
                             aria-hidden="true"></span>}
                                 </button>
                                 <div className="text-center d-none">
                                     <p className="fs-14 text-muted mt-3">Don't have an account yet ? <Link to="/register" className="text-primary fw-semibold"> Sign up here</Link></p>
                                 </div>
                                 </div>
                             </div>
                           </form>
                       </div>
                   </div>
               
       </div>
   </div>
   <ToastContainer />
 </div>
          
        </Modal.Body>
      </Modal>
  
);
}
export default LoginModal;
