import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import {MaterialReactTable} from "material-react-table";

import { BsFillPencilFill } from "react-icons/bs";
import { MdLockOutline, MdOutlinePersonRemoveAlt1, MdOutlinePersonAddAlt1 } from "react-icons/md";

import { Button, IconButton, Tooltip, FormControl, FormControlLabel,Switch, } from "@mui/material";

import BlockLoader from "../../blockloader/BlockLoader"
import UserContext from "../../../context/userProfile-list/UserProvider";
import { useNavigate } from "react-router-dom";
const initialPagination = {
	pageIndex: 0,
	pageSize: 10,
};
const UserDashBoard = () => {
    const  navigate = useNavigate(); 
    const { UserProfileAPI,profileList,totalRow,isLoading,getUserProfileDetails,isUserDetailLoading} = useContext(UserContext);
   
   

const [pagination, setPagination] = useState(initialPagination);
useEffect(() => {
	UserProfileAPI(pagination);
   
  }, [pagination.pageIndex, pagination.pageSize]);
const columns = useMemo(
	() => [
		{
			header: "Image",
			Cell: ({ cell, row }) => (
			<>
				
                <img src={row.original.imgaeString?`data:image/png;base64,${row.original.imgaeString}`:""} alt="img" className="avatar avatar-sm rounded-circle" />
				
					
			</>
				
			),

		},
		{
			accessorKey: "firstName",
			header: "First Name",
			Cell: ({ cell, row }) => (cell.getValue() ? cell.getValue() : "-"),

		},
		{
			accessorKey: "lastName",
			header: "Last Name",
			Cell: ({ cell, row }) => (cell.getValue() ? cell.getValue() : "-"),

		},
		{
			accessorKey: "email",
			header: "Email ID",
			Cell: ({ cell, row }) => (cell.getValue() ? cell.getValue() : "-"),

		},
		{
			accessorKey: "gender",
			header: "Gender",
			Cell: ({ cell, row }) => (cell.getValue() ? cell.getValue() : "-"),

		},
		
		{
			header: "Actions",
			Cell: ({ cell, row }) => (
				<div id="userdropMenu">
					<icon>
					<BsFillPencilFill className="mx-2" color="#073763" title="Edit" 
						onClick={ () => {getUserProfileDetails(row.original.userId,"DashBoard")}}
					/>
					</icon>
					<icon>
					<MdLockOutline className="mx-2" color="#073763" title="Reset Password" />
					</icon>
					
					
				</div>
			),

		},
	],
	[]
);
    return(
        <>
		{isUserDetailLoading && <BlockLoader position={"fixed"} />}
        {/* Start::app-content */}
        <div className="main-content app-content ">
          <div className="container-fluid">
            {/* Page Header */}
            <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
              <h1 className="page-title fw-medium fs-24 mb-0">User Dashboard</h1>
              <div className="ms-md-1 ms-0">
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a href="javascript:void(0);">Home</a>
                    </li>
                    <li
                      className="breadcrumb-item active d-inline-flex"
                      aria-current="page"
                    >
                       User Dashboard
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            {/* Page Header Close */}
          
            {/* Start::row-2 */}
            <div className="row">
              <div className="col-xxl-12">
                <div className="card custom-card ">
                  <div className="card-header">
                    <div className="card-title">User List</div>
                  </div>
                  <div className="card-body p-0">
                  <MaterialReactTable
							columns={columns}
							data={profileList.length > 0 ? profileList : []}
							enableGlobalFilter={false}
							enableStickyHeader
							enableDensityToggle={false}
							initialState={{
								density: "compact",
								showColumnFilters: false,
								pagination: { pageSize: 10, pageIndex: 0 },
							}}
							positionActionsColumn="last"
							manualPagination
							muiTablePaginationProps={{
								rowsPerPageOptions: [10, 50, 100, 200, 500],
							}}

							onPaginationChange={setPagination}
							rowCount={totalRow}
							
							// renderTopToolbarCustomActions={() => (
							// 	<div className="d-flex align-items-center">
							// 		<Tooltip arrow title="Refresh Data">
							// 			<IconButton  color="primary">
							// 				<RefreshIcon fontSize="medium" />
							// 			</IconButton>
							// 		</Tooltip>
							// 	</div>
							// )}
							
							state={{
								showProgressBars: isLoading,
								isLoading,
								pagination,
								
							}}
						/>
                  </div>
                </div>
              </div>
              
            </div>
            {/* End::row-2 */}
           
          </div>
        </div>
        {/* End::app-content */}
      </>
      
  
);
}
export default UserDashBoard;
